<div class="lg:hidden" tabindex="-1">
  <div (click)="isOpenChange.emit(false)" @backdropAnimations *ngIf="isOpen" class="fixed inset-0 bg-black/30"></div>

  <div @sidebarAnimations *ngIf="isOpen" class="fixed inset-y-0 w-full max-w-80 p-2">
    <div class="flex h-full flex-col rounded-lg bg-white shadow-sm ring-1 ring-zinc-950/5">
      <div class="-mb-3 px-4 pt-3">
        <span class="relative">
          <button
            (click)="isOpenChange.emit(false)"
            class="cursor-pointer relative flex p-2 text-left font-medium text-zinc-950">
            <span class="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2"></span>
            <div icon="close" class="flex-none w-8 h-0 mt-[-2px] text-zinc-400" [weight]="600"></div>
          </button>
        </span>
      </div>

      <mt-sidebar-content [sidebarItems]="sidebarItems"></mt-sidebar-content>
    </div>
  </div>
</div>
