<nav class="flex h-full min-h-0 flex-col">
  <div class="flex flex-col p-4">
    <img ngSrc="/images/logo.svg" class="w-32 ml-4 max-lg:hidden" height="16" width="16" />
  </div>

  <div class="flex flex-1 flex-col overflow-y-auto pt-2 px-4">
    <ng-container *ngFor="let item of sidebarItems">
      <div *ngIf="item.type == 'heading'" class="max-lg:hidden flex flex-col gap-0.5 mt-2">
        <h3 class="mb-1 px-2 text-xs/6 font-medium text-zinc-500">{{ item.name }}</h3>
      </div>

      <span *ngIf="item.type == 'link'" class="relative">
        <a
          [routerLink]="item.link"
          routerLinkActive="active bg-blue-600 text-white *:hover:text-white"
          class="peer flex w-full rounded-lg px-1 py-2 font-medium text-sm/5 *:hover:text-blue-700">
          <div [icon]="item.icon" class="flex-none w-8 h-0 mt-[-3px]" [fill]="!!item.fill" [weight]="200"></div>
          <div class="flex-auto truncate">{{ item.name }}</div>
        </a>
        <span class="absolute inset-y-2 -left-4 w-0.5 rounded-full bg-zinc-950 hidden peer-[.active]:block"></span>
      </span>
    </ng-container>
  </div>

  <div class="max-lg:hidden flex flex-col p-4">
    <mt-dropdown-round
      [isOpen]="isAccountDropdownOpen"
      [items]="accountDropdownItems"
      dropDirection="up"></mt-dropdown-round>

    <button
      class="account-button"
      (click)="isAccountDropdownOpen = !isAccountDropdownOpen"
      (blur)="isAccountDropdownOpen = false">
      <span class="flex min-w-0 items-center gap-3">
        <span class="size-10 outline-black/[20%] outline outline-1 -outline-offset-1 rounded-[20%] *:rounded-[20%]">
          <img class="size-full" src="/users/erica.jpg" />
        </span>

        <span class="min-w-0">
          <span class="block truncate text-sm/5 font-medium text-zinc-950">Erica</span>
          <span class="block truncate text-xs/5 font-normal text-zinc-500">My Account</span>
        </span>
      </span>

      <div icon="keyboard_arrow_up" class="flex-none w-8 h-0 text-zinc-500 ml-auto mt-[-26px] text-base"></div>
    </button>
  </div>
</nav>
