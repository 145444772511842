import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { DropdownComponent } from '../../core/components/dropdown/dropdown.component'
import { NavbarComponent } from './nav/navbar.component'
import { SidebarContentComponent } from './nav/sidebar-content.component'
import { NgIf } from '@angular/common'
import { SidebarMobileComponent } from './nav/sidebar-mobile.component'
import { Icon } from '../../core/components/icon/icon.directive'
import { SidebarItem } from './nav/data/sidebar-item.type'

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [
    RouterModule,
    DropdownComponent,
    NavbarComponent,
    NavbarComponent,
    SidebarContentComponent,
    NgIf,
    SidebarMobileComponent,
    Icon,
  ],
  selector: 'mt-app-layout',
  templateUrl: 'app-layout.component.html',
  styleUrl: 'app-layout.component.css',
})
export class AppLayoutComponent {
  @Input({ required: true }) sidebarItems!: SidebarItem[]
  showMobileSidebar: boolean = false
}
