import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { dropdownAnimations } from '../../../core/components/dropdown/dropdown.animations'
import { Icon } from '../../../core/components/icon/icon.directive'
import { DropdownRoundComponent } from '../../../core/components/dropdown/dropdown-round.component'
import { accountDropdownItems } from './data/account-dropdown-items.data'
import { SidebarItem } from './data/sidebar-item.type'
import { RouterLink, RouterLinkActive } from '@angular/router'

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,

  selector: 'mt-sidebar-content',
  templateUrl: 'sidebar-content.component.html',
  styleUrls: ['sidebar-content.component.css'],

  imports: [CommonModule, DropdownRoundComponent, NgOptimizedImage, Icon, RouterLink, RouterLinkActive],
  animations: [dropdownAnimations],
})
export class SidebarContentComponent {
  @Input({ required: true }) sidebarItems!: SidebarItem[]

  isAccountDropdownOpen = false
  protected readonly accountDropdownItems = accountDropdownItems
}
