<nav class="flex flex-1 items-center gap-4 py-2.5">
  <div class="-ml-4 flex-1"></div>

  <button
    class="account-button mr-[-20px]"
    (click)="isAccountDropdownOpen = !isAccountDropdownOpen"
    (blur)="isAccountDropdownOpen = false">
    <span class="flex min-w-0 items-center gap-3">
      <span class="size-7 outline-black/[20%] outline outline-1 -outline-offset-1 rounded-[20%] *:rounded-[20%]">
        <img class="size-full" src="/users/erica.jpg" />
      </span>

      <span class="min-w-0">
        <span class="block truncate text-sm/5 font-medium text-zinc-950">
          Erica
          <span
            icon="keyboard_arrow_down"
            class="flex-none h-0 text-zinc-500 ml-auto mt-[-26px] text-base align-middle"></span>
        </span>
      </span>
    </span>
  </button>

  <mt-dropdown-round [isOpen]="isAccountDropdownOpen" [items]="accountDropdownItems" align="right"></mt-dropdown-round>
</nav>
