import { animate, style, transition, trigger } from '@angular/animations'

export const backdropAnimations = trigger('backdropAnimations', [
  transition(':enter', [style({ opacity: 0 }), animate('0.2s ease-in', style({ scale: 1, opacity: 1 }))]),
  transition(':leave', [animate('0.2s ease-out', style({ opacity: 0 }))]),
])

export const sidebarAnimations = trigger('sidebarAnimations', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('0.2s ease-in', style({ transform: 'translateX(0px)' })),
  ]),
  transition(':leave', [animate('0.2s ease-out', style({ transform: 'translateX(-100%)' }))]),
])
