<div class="relative isolate flex min-h-svh w-full max-lg:flex-col bg-zinc-100">
  <!-- Desktop Sidebar -->
  <div class="fixed inset-y-0 left-0 w-64 max-lg:hidden">
    <mt-sidebar-content [sidebarItems]="sidebarItems"></mt-sidebar-content>
  </div>

  <!-- Mobile Navbar -->
  <header class="flex items-center px-4 lg:hidden border-b border-b-zinc-400/30 bg-white">
    <div class="py-2.5">
      <span class="relative">
        <button class="" (click)="showMobileSidebar = true">
          <div icon="menu"></div>
        </button>
      </span>
    </div>
    <div class="min-w-0 flex-1">
      <app-navbar></app-navbar>
    </div>
  </header>

  <!-- Mobile sidebar -->
  <mt-sidebar-mobile [sidebarItems]="sidebarItems" [(isOpen)]="showMobileSidebar" />

  <!-- Content -->
  <main class="lg:pl-64 w-full p-2">
    <router-outlet></router-outlet>
  </main>
</div>
