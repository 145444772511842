import { ChangeDetectionStrategy, Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DropdownComponent } from '../../../core/components/dropdown/dropdown.component'
import { Icon } from '../../../core/components/icon/icon.directive'
import { DropdownRoundComponent } from '../../../core/components/dropdown/dropdown-round.component'
import { accountDropdownItems } from './data/account-dropdown-items.data'

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,

  selector: 'app-navbar',
  imports: [CommonModule, DropdownComponent, Icon, DropdownRoundComponent],
  templateUrl: 'navbar.component.html',
})
export class NavbarComponent {
  isAccountDropdownOpen: boolean = false
  protected readonly accountDropdownItems = accountDropdownItems
}
