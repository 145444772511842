import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'

@Directive({
  standalone: true,
  selector: '[icon]',
})
export class Icon implements OnInit {
  @Input('icon') set icon(value: string) {
    this.el.nativeElement.innerText = value
  }

  private _fill: boolean = false
  @Input('fill') set fill(value: boolean) {
    this._fill = value
    this.refreshStyle()
  }

  private _weight = 400
  @Input('weight') set weight(value: 100 | 200 | 300 | 400 | 500 | 600 | 700) {
    this._weight = value
    this.refreshStyle()
  }

  private _grade = 0
  @Input('grade') set grade(value: -25 | 0 | 200) {
    this._grade = value
    this.refreshStyle()
  }

  private _opticalSize = 24
  @Input('opticalSize') set opticalSize(value: 20 | 24 | 40 | 48) {
    this._opticalSize = value
    this.refreshStyle()
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'material-symbols-outlined')
  }

  refreshStyle = () => {
    const value = `'FILL' ${this._fill ? 1 : 0}, 'wght' ${this._weight}, 'GRAD' ${this._grade}, 'opsz' ${this._opticalSize}`
    this.renderer.setStyle(this.el.nativeElement, 'font-variation-settings', value)
  }
}
