<div *ngIf="isOpen" @dropdownAnimations class="relative inline-block text-left">
  <div
    class="dropdown"
    [class.left-0]="align == 'left'"
    [class.right-0]="align == 'right'"
    [class.bottom-2]="dropDirection == 'up'"
    [class.top-2]="dropDirection == 'down'">
    <ng-container *ngFor="let item of items">
      <div *ngIf="item == 'separator'" class="dropdown-separator"></div>

      <a *ngIf="item !== 'separator'" tabindex="-1" class="dropdown-item" (click)="item.callback && item.callback()">
        <div [icon]="item.icon" [fill]="!!item.fill" class="text-lg mr-2"></div>
        <div class="col-start-2 row-start-1">{{ item.name }}</div>
      </a>
    </ng-container>
  </div>
</div>
