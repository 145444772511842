import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { DropdownComponent } from '../../../core/components/dropdown/dropdown.component'
import { SidebarContentComponent } from './sidebar-content.component'
import { backdropAnimations, sidebarAnimations } from './sidebar-mobile.animations'
import { Icon } from '../../../core/components/icon/icon.directive'
import { SidebarItem } from './data/sidebar-item.type'

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,

  selector: 'mt-sidebar-mobile',
  imports: [CommonModule, DropdownComponent, NgOptimizedImage, SidebarContentComponent, Icon],
  templateUrl: 'sidebar-mobile.component.html',
  animations: [backdropAnimations, sidebarAnimations],
})
export class SidebarMobileComponent {
  @Input({ required: true }) sidebarItems!: SidebarItem[]

  @Input() isOpen = true
  @Output() isOpenChange = new EventEmitter<boolean>()
}
